<template>
  <footer
    class="pb-6"
    :class="[absolute ? 'absolute w-full bottom-0' : 'relative']"
  >
    <div class="container mx-auto px-4">
      <hr class="mb-6 border-b-1 border-gray-200" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full">
          <div
            class="text-sm text-gray-500 font-semibold py-1 text-right"
          >
            Copyright © {{ date }}
            <a
              href="https://magidata.ru"
              class="text-gray-500 hover:text-gray-400 text-sm font-semibold py-1"
            >
              MAGIDATA.RU
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      date: new Date().getFullYear()
    }
  },
  props: {
    absolute: {
      type: Boolean,
      default: false
    }
  }
}
</script>
